import React from "react";
import SEO from "../components/seo";
import Header from "../components/header";
import Hero from "../components/hero";
import ServiceBar from "../components/serviceBar";
//import Samples from "../components/samples";
import Review from "../components/review";
import Section from "../components/section";
import Layout from "../components/layout";
//import H1 from "../components/sectionHeading";
import { 
  Button, 
  Row, 
  Col
} from "react-bootstrap";

import { useStaticQuery, graphql } from "gatsby";


const Home = () => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  
  return (
  <Layout>
    <SEO title="Webdev & Puzzles" path=''/>
    <Header siteTitle={data.site.siteMetadata.title} />
    
    {/*
    <Hero subtitle="Application & Education" title='Web Development'>
      <Button className="cg-dark" href="#contactForm" style={{marginTop:'2rem'}}>Contact</Button>
    </Hero>
    */}
      
    {/*
    <Section className='cg-section-shaded' contact>
      <Row>
        <Col>
          <H1>How long do you have?</H1>
          <div className='copy'>
            <ul>
              <li>Not long...</li>
            </ul>
            <p>
              Most <strong>web visitors leave within seconds</strong> of landing on a new page. 
            </p>
            <p>
              An ineffective website lets visitors slip away - often never to return. 
              (They probably didn't see all your great content.)
            </p>
            
            <p> 
              However, <strong>there is a solution</strong>.
            </p>
            <p>
              To make your website work for you, contact us. We'll help you <strong>get and keep sales&nbsp;leads</strong>.
            </p>
          </div>
        </Col>
      </Row>
    </Section>
    */}
    
    <ServiceBar />
    
    <hr/>
    
    <Section id='guide' className='cg-section-shaded'>
          {/*
      <Row>
        <Col>
          <H1>Our work</H1>
          <p>
            A website that doesn't bring in leads is frustrating. But it doesn't have to stay that way. 
            We've helped others, and we can help you, too:
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Samples />
        </Col>
      </Row>
          */}
      <Row>
        <Col>
          <Review name='Travis W.' title='Customer Contact'>
            We had an outdated site and Congius has brought us to a better functioning site with better contact points for potential customers to get to us. All was simple and done through a few emails. 
          </Review>
        </Col>
      </Row>
      <Row>
        <Col md>
          <Review name='Ron R.' title='Talented'>
            Ben did a great job on the development of <Para>our webapp</Para>. Very talented.
          </Review>
        </Col>
        <Col md>
          <Review name='Susan B.'>
            Ben, you were awesome, kind, and a pleasure to work with. 
          </Review>
        </Col>
      </Row>
    </Section>
    
  </Layout>
)};

export default Home;


function Para(props) {
  return (
    <React.Fragment>
      <span className='cg-paraphrase'>[</span>{props.children}<span className='cg-paraphrase'>]</span>
    </React.Fragment>
      );
}
