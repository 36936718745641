import React from "react";
import Stars from "../components/stars";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'

const Review = ({title, name, thumbtack, children}) => (
  <Card className='cg-reviewCard'>
    <Card.Body>
      {/*
      <Card.Title>{title}</Card.Title>
      <Card.Subtitle>
      </Card.Subtitle>
      */}
        <Stars className='rating'/>
      <Card.Text>
          <FontAwesomeIcon icon={faQuoteLeft} className='quoteChar'/>
          <span className='quoteText'> {children} </span>
          <span className='attribution'>
            ~&nbsp;{name}
          </span>
      </Card.Text>
    </Card.Body>
  </Card>
);

export default Review;