import React from "react";
import {Container,Row,Col,Card} from "react-bootstrap";
// import Img from "gatsby-image";
// import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faGlobeAmericas, 
  //faMobileAlt, 
  faPuzzlePiece,
  faChalkboardTeacher,
  //faBullhorn 
} from '@fortawesome/free-solid-svg-icons';
import H1 from "./sectionHeading";
import { navigate } from 'gatsby';

const Services = () => {
  
//   const data = useStaticQuery(graphql`
//   query {
//     romeutica: file(relativePath: { eq: "romeutica-logo1.png" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     },
//     maint: file(relativePath: { eq: "logo-maint-4-nosubtitle.png" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     },
//     salecode: file(relativePath: { eq: "logo-salecode.png" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `)
  
    return (
      <div  id="cg-serviceBar" style={{margin: '0 auto 4rem auto', padding:' 1.45rem', width:'100%', borderStyle: 'none'}}>
        {/*<H1>Our Services</H1>*/}
        <Container fluid>
          <Row>
          
            <Col sm>
              <Card className='cg-serviceCard' onClick={()=>navigate('#contactForm')}> {/* onClick={()=>navigate('/services#copywriting')}> */}
                <Card.Body>
                  <FontAwesomeIcon icon={faGlobeAmericas}  className='cg-serviceIcon' />
                  <Card.Title>Websites</Card.Title>
                  <Card.Text>
                    <p>Design & Education</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            
            <Col sm>
              <Card className='cg-serviceCard' onClick={()=>navigate('#contactForm')}> {/* onClick={()=>navigate('/services#copywriting')}> */}
                <Card.Body>
                  <FontAwesomeIcon icon={faPuzzlePiece}  className='cg-serviceIcon' />
                  <Card.Title>Mechanical Puzzles</Card.Title>
                  <Card.Text>
                    <p>Design & Manufacturing of 3D printed puzzles</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            {/*
            <Col sm>
              <Card className='cg-serviceCard' onClick={()=>navigate('#contactForm')}>
                <Card.Body>
                  <FontAwesomeIcon icon={faMobileAlt}  className='cg-serviceIcon' />
                  <Card.Title>Apps</Card.Title>
                  <Card.Text>Webapp development: PWA & serverless</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            */}
            
            {/*
            <Col sm>
              <Card className='cg-serviceCard' onClick={()=>navigate('#contactForm')}>
                <Card.Body>
                  <FontAwesomeIcon icon={faChalkboardTeacher}  className='cg-serviceIcon' />
                  <Card.Title>Education</Card.Title>
                  <Card.Text>
                    <p>Individual and group mentorship in web development</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            */}
            
            {/*
            <Col sm>
              <Card className='cg-serviceCard' onClick={()=>navigate('/services#copywriting')}>
                <Card.Body>
                  <FontAwesomeIcon icon={faFileAlt}  className='cg-serviceIcon' />
                  <Card.Title>Copywriting</Card.Title>
                  <Card.Text>The wording on your website should be making you sales. If not, you're missing out!</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm>
              <Card className='cg-serviceCard' onClick={()=>navigate('/services#marketing')}>
                <Card.Body>
                  <FontAwesomeIcon icon={faBullhorn}  className='cg-serviceIcon' />
                  <Card.Title>Marketing</Card.Title>
                  <Card.Text>Make a clear path for customers to follow: from landing page, to email list, to sales offers.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            */}
          </Row>
        </Container>
      </div>
    );
}

export default Services;