import React from "react";


class Hero extends React.Component {
  //constructor(props) {
  //    super(props);
  //}

  render() {
    return (
      <div style={{padding:'1.5rem'}}>
        <div className='contentBox jumbotron abovefold' >
          {this.props.pretitle && <div className='heroPretitle'>{this.props.pretitle}</div>}
          <h1 className='display-4'>{this.props.title}</h1>
          {this.props.subtitle && <div className='heroPretitle'>{this.props.subtitle}</div>}
          <p className='lead' style={{maxWidth:'25rem', margin: '0 auto'}}>
            {this.props.children}
          </p>
        </div>
    </div>
    );
  }
}

export default Hero;