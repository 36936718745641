import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "@reach/router";
import Img from "gatsby-image";

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo8_64.png" }) {
        childImageSharp {
          fixed(width: 64) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Link
        to="/"
        style={{
          color: `black`,
          textDecoration: `none`
        }}
    >
      <Img 
        fixed={data.placeholderImage.childImageSharp.fixed} 
        style={{
          width: 64,
          display: 'inline-block'
        }}
      />
    </Link>
  )
}

export default Logo
